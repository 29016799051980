import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';

const Recensies = () => (
  <Layout>
    <SEO
      title="Recensies"
      description="Onze recensies, want je hoeft ons niet te geloven. Er zijn alleen maar 'super'-latieven: super-lekkere pizza's, super-snel klaar, super-vriendelijke en professionele service!"
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{ backgroundImage: 'url(/design/img/header/bediening.jpg)' }}
    >
      <div className="container">
        <Slogan heading="Onze recensies, want je hoeft ons niet te geloven" />
      </div>
    </header>

    <section className="bg-color1 py-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-lg-offset-2 col-md-offset-1">
            <div className="slider">
              <ul data-component="slider">
                <li>
                  <p className="quote quote-lg">
                    Er zijn alleen maar "super"-latieven: super-lekkere pizza's,
                    super-snel klaar, super-vriendelijke en professionele
                    service!
                  </p>
                </li>
                {/* <li>
                  <p className="quote quote-lg">
                    Mocht je nog twijfelen: de perfecte verhouding prijs,
                    kwaliteit en service ga je nergens anders vinden!
                  </p>
                </li>
                <li>
                  <p className="quote quote-lg">
                    Wij maken zeker nog gebruik. Een echte aanrader.
                  </p>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="quote-block bg-color1 pt-0 pb-6">
      <div className="container">
        <h2 className="title dark-bg">Wat zeggen onze klanten</h2>
        <h4 className="h5 dark-bg mb-4">
          Een greep uit onze{' '}
          <a href="https://www.facebook.com/Attorno.be/">Facebook pagina</a>
        </h4>
        <div className="row eh-grid">
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Net een lentefeest achter de rug dat werd verzorgd door het
                professionele team van Attorno. Zeer veel variatie in de
                pizza's, die allemaal even verrukkelijk waren. Keuze genoeg voor
                vleeseters, visliefhebbers en vegetariërs. Deze mannen wisten
                duidelijk waar ze mee bezig waren. En het beste van alles: geen
                afwas of opkuis nadien! Mocht je nog twijfelen: de perfecte
                verhouding prijs, kwaliteit en service ga je nergens anders
                vinden!
              </p>
              <small>Karel</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Een heel dikke "merci" aan de mensen van Attorno om onze
                huwelijksfuif net dat beetje (véél) extra te geven met jullie
                concept!! Iedereen was vol lof! Er zijn alleen maar
                "super"-latieven: super-lekkere pizza's, super-snel klaar,
                super-vriendelijke en professionele service! Een echt aanrader!!
              </p>
              <small>Sophie</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                We wouden net iets extra doen voor ons feest. De snelle en
                professionele houding via e-mail overtuigde ons al snel om het
                vertrouwen aan Attorno te gunnen. Vanaf de aankomst tot het
                einde was het een unieke en lekkere beleving!
                <br />3 enthousiastelingen voorzien de aanwezigen van
                overheerlijke pizza's. Naast enkele standaard pizza's werden ook
                heel wat (voor mij) nieuwe creaties gemaakt. Wij maken zeker nog
                gebruik. Een echte aanrader.
              </p>
              <small>Tom</small>
            </div>
          </div>
        </div>
        <div className="row eh-grid">
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Attorno kwam pizza's bakken op de babyborrel van onze dochter.
                Contact verliep vlot, alsook de afgesproken timing. Kortom: sit
                back, relax &amp; eat pizza's Bedankt Attorno!!
              </p>
              <small>Sam</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Alles was goed in orde. Iedereen op ons feestje was super
                enthousiast en vonden de pizza's heel lekker. Het was eens iets
                anders dan de klassieke barbecues. Dank je wel Attorno voor de
                goede service, heerlijke pizza's, vriendelijke bediening en zo
                veel meer. Zeker een aanrader, en voor ons, voor herhaling
                vatbaar.
              </p>
              <small>Britt</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Het was echt een goede ervaring. De professionaliteit van het
                personeel zorgde ervoor dat onze gasten niets te kort kwamen.
              </p>
              <small>Melissa</small>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact dark="true" />

    <Footer />
  </Layout>
);

export default Recensies;
